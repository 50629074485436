import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Alert,
  Table,
  Button,
} from "reactstrap";

import { AvForm } from "availity-reactstrap-validation";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import { BxButton } from "../../Button";
import { AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";
import "./storageRent.scss";
import {
  useCustomerContext,
  CUSTOMER_DIALOG_TYPES,
} from "../../../context/CustomerProviderContext";
import { useBranchContext } from "../../../context/BranchProviderContext";

import { Customer } from "src/models/customer";
import { MultiLangText } from "src/models/multiLangText";
import RentalPeriod, { RentPeriod } from "./RentalPeriod";
import { Branch } from "src/models/branch";

import { Order } from "src/models/order";
import { Contract } from "src/models/contract";
import { getBoxappContractBackend } from "src/helpers/boxapp_contract_helper";
import { Storage } from "src/models/storage";
import { RentMode } from "./StorageRent";
import Decimal from "decimal.js";
import { Tbody, Thead } from "react-super-responsive-table";

export interface ContractPanelProps {
  customer?: Customer;
  storage?: Storage;
  mode: string;
  totalSteps: number;
  onCancel: () => void;
  isFinished: boolean;
  open: boolean;
  step: number;
  branch: Branch;
  order?: Order;
  startDate: number;
  contract?: Contract;
  rentMode: RentMode;
  onSubmit: (data: any) => void;
  onContractConflit: (data?: Contract) => void;
}

const ContractPanel = (props: ContractPanelProps) => {
  const [customer, setCustomer] = useState<Customer>();
  const { i18n } = useTranslation();
  const [remarks, setRemarks] = useState<string>("");
  const [discountAmount, setDiscountAmount] = useState<Decimal>();
  const [originalPrice, setOriginalPrice] = useState<Decimal>();
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  const [rentType, setRentType] = useState<"YEAR" | "MONTH" | "WEEK" | "DAY">();
  const [rentPeriod, setRentPeriod] = useState<number>(0);
  const [contract, setContract] = useState<Contract>();
  const [contracts, setContracts] = useState<Contract[]>();
  const { showDialog, hideDialog } = useCustomerContext();
  const { contextBranch } = useBranchContext();

  const [isAddingMonthChecked, setIsAddingMonthChecked] = useState(false);
  const [addFreeMonthsCount, setAddFreeMonthsCount] = useState<number>(0);
  const [freeMonths, setFreeMonths] = useState<number>(0);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [freeDays, setFreeDays] = useState<number>(0);
  const daysRemaining: number | undefined = useMemo(() => {
    if (!props?.contract?.end_date) {
      return undefined;
    }
    if (
      !props?.storage?.rent_records ||
      !props?.storage?.rent_records?.[0]?.order?.actual_end_date
    ) {
      return undefined;
    }
    return Math.floor(
      moment
        .duration(
          moment
            .unix(props?.contract?.end_date)
            .diff(
              moment.unix(
                props?.storage?.rent_records[0]?.order?.actual_end_date
              )
            )
        )
        .asDays()
    );
  }, [
    props?.contract?.end_date,
    props?.storage?.rent_records?.[0]?.order?.actual_end_date,
  ]);

  const finalPrice = useMemo(
    () => new Decimal(originalPrice ?? 0).minus(discountAmount ?? 0),
    [originalPrice, discountAmount]
  );
  const [isOpen, setIsOpen] = useState(false);
  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (startDate && endDate && customer?.id) {
      getBoxappContractBackend()
        .getContracts({
          storage_id: props.storage?.id,
          customer_id: customer?.id,
          start_date: startDate,
          end_date: endDate,
          status: "ACTIVE",
        })
        .then((res) => {
          if (props.onContractConflit) {
            props.onContractConflit(res?.contracts?.data?.[0] as Contract);
          }
          setContract(res?.contracts?.data?.[0] as Contract);
        })
        .catch((err) => {
          if (props.onContractConflit) {
            props.onContractConflit(undefined);
          }
          setContract(undefined);
        });
    } else {
      if (props.onContractConflit) {
        props.onContractConflit(undefined);
      }
      setContract(undefined);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setCustomer(undefined);
    setContract(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setRentPeriod(0);
    setRentType(undefined);
    switch (props.rentMode) {
      case RentMode.ContinueRent: {
        setCustomer(props.customer);
        setContract(props?.contract);
        setStartDate(props.contract?.start_date!);
        setEndDate(props.contract?.end_date);
        break;
      }
      case RentMode.NewContract: {
        setCustomer(props.customer);
        setContract(props.contract);
        if (props?.contract) {
          const start_date = moment
            .unix(props?.contract?.end_date)
            .add(1, "days")
            .unix();
          setStartDate(start_date);
          setRentPeriod(props?.contract?.rent_period);
          setRentType(props?.contract?.rent_type);
          setEndDate(
            moment
              .unix(start_date)
              .add(
                props?.contract?.rent_period,
                props?.contract?.rent_type?.toLowerCase() as any
              )
              .subtract(1, "days")
              .unix()
          );
        }

        break;
      }
      case RentMode.NewMasterContract: {
        setCustomer(undefined);
        setStartDate(moment().unix());
        break;
      }
      default: {
      }
    }
  }, [props.rentMode]);

  const searchCustomer = () => {
    if (!contextBranch) {
      return;
    }
    showDialog(CUSTOMER_DIALOG_TYPES.SEARCH_MODAL, {
      branch: contextBranch,
      onSearched: (customer) => {
        setCustomer(customer);
        hideDialog();
      },
      onClose: () => {
        hideDialog();
      },
    });
  };

  {
    /* DISCOUNT CONST SECTION START */
  }
  const applyDiscount = (percentage: number) => {
    const discount = new Decimal(originalPrice ?? 0)
      .times(percentage / 100)
      .toNumber();
    setDiscountAmount(new Decimal(discount));
    const remarkText = i18n.t("Applied {{percentage}}% Discount", {
      percentage,
    });
    setRemarks((prev) => (prev ? `${prev}[${remarkText}]` : `[${remarkText}]`));
  };

  const [discountType, setDiscountType] = useState<
    "percentage" | "freeMonths" | "addFreeMonths" | "freeDays" | null
  >(null);

  const handleCheckboxChange = (
    type: "percentage" | "freeMonths" | "addFreeMonths" | "freeDays"
  ) => {
    setRemarks((prev) => (prev ? prev.replace(/\[.*?\]/g, "") : ""));

    if (discountType === type) {
      if (type === "addFreeMonths" && isAddingMonthChecked) {
        // toggleFreeMonths(addFreeMonthsCount);
        setIsAddingMonthChecked(false);
      }
      setDiscountType(null);
      resetInputs();
    } else {
      setDiscountType(type);
    }
  };

  const applyFreeDaysAsDiscount = (days: number) => {
    if (
      originalPrice !== undefined &&
      startDate !== undefined &&
      endDate !== undefined
    ) {
      const startDateMoment = moment.unix(startDate);
      const endDateMoment = moment.unix(endDate);
      const totalDays = endDateMoment.diff(startDateMoment, "days") + 1;

      const dailyPrice = new Decimal(originalPrice).div(totalDays);
      const discount = Math.round(dailyPrice.times(days).toNumber());
      setDiscountAmount(new Decimal(discount));

      const remarkText = i18n.t("Applied {{days}} Day(s) Free as Discount", {
        days,
      });
      setRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
    }
  };

  const applyFreeMonthsAsDiscount = (months: number) => {
    if (
      originalPrice !== undefined &&
      startDate !== undefined &&
      endDate !== undefined
    ) {
      let discount;
      const startDateMoment = moment.unix(startDate);
      const endDateMoment = moment.unix(endDate);
      const totalDays = endDateMoment.diff(startDateMoment, "days");

      if (rentType === "MONTH") {
        const monthlyPrice = new Decimal(originalPrice).div(rentPeriod);
        discount = Math.round(monthlyPrice.times(months).toNumber());
      } else {
        const monthlyPrice = new Decimal(originalPrice).div(totalDays / 30);
        discount = Math.round(monthlyPrice.times(months).toNumber());
      }

      setDiscountAmount(new Decimal(discount));
      const remarkText = i18n.t(
        "Applied {{months}} Month(s) Free as Discount",
        {
          months,
        }
      );
      setRemarks((prev) =>
        prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
      );
    }
  };

  const toggleFreeMonths = (months: number) => {
    if (
      startDate !== undefined &&
      endDate !== undefined &&
      rentPeriod !== undefined
    ) {
      let newRentPeriod = rentPeriod;

      if (rentType === "MONTH") {
        newRentPeriod = isAddingMonthChecked
          ? (rentPeriod || 0) - months
          : (rentPeriod || 0) + months;
      }

      const currentEndDate = moment.unix(endDate);
      const updatedEndDate = currentEndDate
        .add(isAddingMonthChecked ? -months : months, "month")
        .unix();

      setEndDate(updatedEndDate);
      setRentPeriod(newRentPeriod);
      setIsAddingMonthChecked(!isAddingMonthChecked);

      if (!isAddingMonthChecked) {
        const remarkText = i18n.t("Add {{months}} Month(s) Free", { months });
        setRemarks((prev) =>
          prev ? `${prev}[${remarkText}]` : `[${remarkText}]`
        );
      } else {
        const remarkText = i18n.t("Add {{months}} Month(s) Free", { months });
        setRemarks((prev) => (prev ? prev.replace(`[${remarkText}]`, "") : ""));
      }
    }
  };

  const resetInputs = () => {
    setDiscountPercentage(0);
    setFreeMonths(0);
    setAddFreeMonthsCount(0);
    setIsAddingMonthChecked(false);
    setDiscountAmount(new Decimal(0));
  };

  {
    /* DISCOUNT CONST SECTION END */
  }

  return (
    <AvForm
      hidden={!props.open}
      action="#"
      id={"CollapseForm" + props.step}
      title={
        !props.isFinished
          ? `${i18n.t("Contract")}`
          : `${i18n.t("Contract")} (${moment
              .unix(startDate as number)
              ?.format("YYYY-MM-DD")} - ${moment
              .unix(endDate as number)
              ?.format("YYYY-MM-DD")})`
      }
      onSubmit={() => {
        if (props.rentMode === RentMode.ContinueRent) {
          if (contract) {
            props.onSubmit({
              ...contract,
              customer: customer,
            });
            return;
          }
        } else if (props.rentMode === RentMode.NewContract) {
          if (contract) {
            // props.onSubmit({
            //   ...contract,
            //   master_contract_id: props?.contract?.master_contract_id,
            //   customer: customer,
            // });

            props.onSubmit({
              ...contract,
              master_contract_id: props?.contract?.master_contract_id,
              start_date: startDate,
              end_date: endDate,
              rent_type: rentType,
              rent_period: rentPeriod,
              customer: customer,
              customer_id: customer?.id as string,
              original_price: Number(originalPrice),
              discount_amount: Number(discountAmount),
              final_price: Number(finalPrice),
              remarks: remarks,
            });
            return;
          }
        }

        if (!customer) {
          console.log("customer");
          return;
        }
        if (!originalPrice) {
          console.log("originalPrice");
          return;
        }
        if (!startDate || !endDate) {
          return;
        }
        if (startDate >= endDate && rentType !== "DAY") return;
        if (finalPrice?.isNegative()) {
          console.log("finalPrice");
          return;
        }

        props.onSubmit({
          ...contract,
          master_contract_id:
            contract?.master_contract_id ?? props?.contract?.master_contract_id,
          start_date: startDate,
          end_date: endDate,
          rent_type: rentType,
          rent_period: rentPeriod,
          customer: customer,
          customer_id: customer?.id as string,
          original_price: Number(originalPrice),
          discount_amount: Number(discountAmount),
          final_price: Number(finalPrice),
          remarks: remarks,
        });
      }}
    >
      <Card outline color="light" className="border">
        <CardBody>
          <FormGroup>
            <Label htmlFor="validationCustomName">
              {i18n.t("Rental Location Address")}:
            </Label>
            <Label htmlFor="validationCustomName">
              {props.storage?.address?.[i18n.language as keyof MultiLangText]}
            </Label>
          </FormGroup>

          <FormGroup className="mt-2">
            <Label htmlFor="validationCustomName">{i18n.t("Customer")}: </Label>

            {props.rentMode === "New Master Contract" && (
              <Row>
                <Col xs="10">
                  <AvField
                    name="customerName"
                    placeholder={i18n.t("Customer Name")}
                    type="text"
                    readOnly
                    errorMessage={i18n.t("Select a customer")}
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustomName"
                    value={customer ? customer.other_name : ""}
                    onClick={() => {
                      if (props.mode !== "extend") {
                        searchCustomer();
                      }
                    }}
                  />
                </Col>

                <Col xs="2">
                  <Link
                    to="#"
                    color="light"
                    className="waves-effect waves-light btn"
                    onClick={() => {
                      if (props.mode !== "extend") {
                        searchCustomer();
                      }
                    }}
                  >
                    <i className="bx bx-search-alt font-size-16 align-middle mr-2"></i>
                  </Link>
                </Col>
              </Row>
            )}
            {(props.rentMode === RentMode.NewContract ||
              props.rentMode === RentMode.ContinueRent) && (
              <Label htmlFor="validationCustomName">
                {`${customer?.last_name} ${customer?.first_name}`}
              </Label>
            )}
          </FormGroup>

          {props.rentMode === RentMode.ContinueRent && (
            <FormGroup className="mt-2">
              <Row>
                <Col>
                  {!daysRemaining ? (
                    <Alert
                      color="warning"
                      className="offcanvas alert-dismissible fade show mt-1 md-1 p-1 text-center font-size-13"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-triangle mr-2"></i>
                      <p>
                        <b>{`${i18n.t("Current Contract")}`}</b>
                      </p>
                      <p>
                        {`${i18n.t("Contract Period")}: ${
                          props?.contract?.end_date
                            ? moment
                                .unix(props?.contract?.start_date)
                                .format("YYYY-MM-DD")
                            : "N/A"
                        } - ${
                          props?.contract?.end_date
                            ? moment
                                .unix(props?.contract?.end_date)
                                .format("YYYY-MM-DD")
                            : "N/A"
                        }`}
                        <br />
                        <p>{`${i18n.t(
                          "Please confirm your New Order is during the Contract period and NO overlapping dates."
                        )}`}</p>
                        <p>{`${i18n.t(
                          "Or you may press the Search Button."
                        )}`}</p>
                      </p>
                      <Button
                        to="#"
                        color="primary"
                        className="waves-effect waves-light btn btn-lg w-lg"
                        onClick={async () => {
                          try {
                            const res =
                              await getBoxappContractBackend().getContracts({
                                storage_id: props.storage?.id,
                                customer_id: customer?.id,
                                status: "ACTIVE",
                              });
                            if (res?.contracts) {
                              setContracts(res.contracts?.data);
                            }
                          } catch (e) {
                            setContracts([]);
                          }
                        }}
                      >
                        <i className="bx bx-search-alt font-size-16 align-middle mr-2">
                          {i18n.t("Search")}
                        </i>
                      </Button>
                    </Alert>
                  ) : daysRemaining === 0 ? (
                    <Alert
                      color="danger"
                      className="offcanvas alert-dismissible fade show mt-1 md-1 p13 text-center font-size-13"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-triangle mr-2"></i>
                      <p>
                        <b>{`${i18n.t("Current Contract")}`}</b>
                      </p>
                      <p>
                        {`${i18n.t("Contract Date")}: ${
                          props?.contract?.end_date
                            ? moment
                                .unix(props?.contract?.end_date)
                                .format("YYYY-MM-DD")
                            : "N/A"
                        }`}
                        <br />
                        {`${i18n.t("Current Order End Date")}: ${
                          props?.storage?.rent_records?.[0]?.order
                            ?.actual_end_date
                            ? moment
                                .unix(
                                  props?.storage?.rent_records?.[0]?.order
                                    ?.actual_end_date
                                )
                                .format("YYYY-MM-DD")
                            : "N/A"
                        }`}
                        <p>{`${i18n.t("Days Remaining on the Contract")}:`}</p>
                        <b>
                          <p className="font-size-15">
                            <u>{`${daysRemaining} ${i18n.t("Days")}`}</u>
                          </p>
                          <p>{`${i18n.t(
                            "Suggest Renew Contract, or check the Rent Record any future contract / Order exist."
                          )}`}</p>
                        </b>
                      </p>
                    </Alert>
                  ) : (
                    <Alert
                      color="success"
                      className="offcanvas alert-dismissible fade show mt-1 md-1 p-1 text-center font-size-13"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-triangle mr-2"></i>
                      <p>
                        <b>{`${i18n.t("Current Contract")}`}</b>
                      </p>
                      <p>
                        {`${i18n.t("Contract Date")}: ${
                          props?.contract?.end_date
                            ? moment
                                .unix(props?.contract?.end_date)
                                .format("YYYY-MM-DD")
                            : "N/A"
                        }`}
                        <br />
                        {`${i18n.t("Current Order End Date")}: ${
                          props?.storage?.rent_records?.[0]?.order
                            ?.actual_end_date
                            ? moment
                                .unix(
                                  props?.storage?.rent_records?.[0]?.order
                                    ?.actual_end_date
                                )
                                .format("YYYY-MM-DD")
                            : "N/A"
                        }`}
                        <p>{`${i18n.t("Days Remaining on the Contract")}:`}</p>
                        <p className="font-size-15">
                          <u>
                            <b>{`${daysRemaining}${i18n.t("Days")}`} </b>
                          </u>
                        </p>
                      </p>
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={10}>
                  <Table>
                    <Thead>
                      <tr>
                        <th>{i18n.t("Contract ID")}</th>
                        <th>{i18n.t("Master Contract ID")}</th>
                        <th>{i18n.t("Contract Period")}</th>
                        <th>{i18n.t("Orignal Price")}</th>
                        <th>{i18n.t("Discount Amount")}</th>
                        <th>{i18n.t("Final Price")}</th>
                      </tr>
                    </Thead>
                    <Tbody>
                      {contracts &&
                        contracts?.length > 0 &&
                        contracts?.map((c, i) => {
                          return (
                            <tr
                              style={{ cursor: "pointer" }}
                              key={i}
                              onClick={() => {
                                setContract(c);
                                setStartDate(c.start_date);
                                setEndDate(c.end_date);
                                setRentType(c.rent_type);
                                setRentPeriod(c.rent_period);
                                setOriginalPrice(
                                  c.original_price
                                    ? new Decimal(c.original_price)
                                    : new Decimal(0)
                                );
                                setDiscountAmount(
                                  c.discount_amount
                                    ? new Decimal(c.discount_amount)
                                    : new Decimal(0)
                                );
                                setRemarks(c.remarks);
                              }}
                            >
                              <td>{c.id}</td>
                              <td>{c.master_contract_id}</td>
                              <td>{`${moment
                                .unix(c.start_date)
                                .format("YYYY-MM-DD")} - ${moment
                                .unix(c.end_date)
                                .format("YYYY-MM-DD")} (${
                                c.rent_period
                              } ${i18n.t(c.rent_type)})`}</td>
                              <td>{c.original_price}</td>
                              <td>{c.discount_amount}</td>
                              <td>{c.final_price}</td>
                            </tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </Col>
                <Col>
                  <Link
                    to="#"
                    color="light"
                    className="waves-effect waves-light btn"
                    onClick={async () => {
                      try {
                        const res =
                          await getBoxappContractBackend().getContracts({
                            storage_id: props.storage?.id,
                            customer_id: customer?.id,
                            status: "ACTIVE",
                          });
                        if (res?.contracts) {
                          setContracts(res.contracts?.data);
                        }
                      } catch (e) {
                        setContracts([]);
                      }
                    }}
                  >
                    <i className="bx bx-search-alt font-size-16 align-middle mr-2">
                      {i18n.t("more")}
                    </i>
                  </Link>
                </Col>
              </Row>
            </FormGroup>
          )}

          {contract && (
            <Row>
              <Col xs="12">
                <Card>
                  <CardHeader
                    className={
                      props.rentMode === RentMode.ContinueRent
                        ? "bg-success"
                        : "bg-warning"
                    }
                  >
                    {props.rentMode === RentMode.ContinueRent
                      ? i18n.t("Contract")
                      : i18n.t(
                          "Found that you have an existing contract in this period. Please use Coniune Rent to create order, or select a new period."
                        )}
                  </CardHeader>
                  <CardBody>
                    <Row className="border-bottom mb-2">
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Master Contract ID")}: <br />
                          {i18n.t(contract?.master_contract_id)}
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Contract ID")}: <br />
                          {i18n.t(contract?.id)}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Contract Period")}: <br />
                          {`${moment
                            .unix(contract?.start_date)
                            .format("YYYY-MM-DD")} - ${moment
                            .unix(contract?.end_date)
                            .format("YYYY-MM-DD")} (${
                            contract?.rent_period
                          } ${i18n.t(contract?.rent_type)})`}
                        </p>
                      </Col>
                    </Row>
                    <Label className="font-weight-bold">
                      {i18n.t("Price")}:
                    </Label>
                    <Row className="border-bottom mb-2">
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Original Price")}: <br />
                          {contract?.original_price}
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Discount Amount")}: <br />
                          {contract?.discount_amount}
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-2">
                          {i18n.t("Final Price")}: <br />
                          {contract?.final_price}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {props.rentMode === RentMode.NewContract && props?.contract && (
            <Row className="border-bottom mb-2">
              <Col xs={12}>
                <p className="mb-2">
                  {i18n.t("Master Contract ID")}: <br />
                  {i18n.t(props?.contract?.master_contract_id)}
                </p>
              </Col>
              <Col xs={12}>
                <Alert
                  color="warning"
                  className="alert-dismissible fade show  mb-4 pt-1 font-size-13"
                  role="alert"
                >
                  <i className="mdi mdi-calendar-arrow-left">
                    {`${i18n.t("Last Contract Start Date")}: `}
                    {props.contract?.start_date
                      ? moment(props.contract?.start_date * 1000).format(
                          "YYYY-MM-DD"
                        )
                      : "N/A"}
                    <br />
                    {`${i18n.t("Last Contract End Date")}: `}
                    {props.contract?.end_date
                      ? moment(props.contract?.end_date * 1000).format(
                          "YYYY-MM-DD"
                        )
                      : "N/A"}
                  </i>
                </Alert>
              </Col>
            </Row>
          )}
          {props.rentMode !== RentMode.ContinueRent && (
            <Row>
              <Col>
                <RentalPeriod
                  name={props.rentMode}
                  allow_yearly={contextBranch?.allow_yearly}
                  allow_monthly={contextBranch?.allow_monthly}
                  allow_weekly={contextBranch?.allow_weekly}
                  allow_daily={contextBranch?.allow_daily}
                  periodTitle={i18n.t("Contract")}
                  startDate={Number(startDate) ?? moment().unix()}
                  endDate={Number(endDate) ?? moment().unix()}
                  defaultRentType={rentType ?? "YEAR"}
                  rentPeriod={Number(rentPeriod) ?? 1}
                  onChange={(e: RentPeriod) => {
                    console.log(e.rent_type);
                    setStartDate(e.start_date);
                    setEndDate(e.end_date);
                    setRentType(e.rent_type);
                    setRentPeriod(e.rent_period);
                    switch (e.rent_type) {
                      case "MONTH":
                        setOriginalPrice(
                          new Decimal(e.rent_period).times(
                            props.storage?.monthly_price ?? 0
                          )
                        );
                        break;

                      case "YEAR":
                        setOriginalPrice(
                          new Decimal(e.rent_period).times(
                            props.storage?.yearly_price ?? 0
                          )
                        );

                        break;
                      case "WEEK":
                        setOriginalPrice(
                          new Decimal(e.rent_period).times(
                            props.storage?.weekly_price ?? 0
                          )
                        );
                        break;

                      case "DAY":
                        setOriginalPrice(
                          new Decimal(e.rent_period).times(
                            props.storage?.daily_price ?? 0
                          )
                        );
                        break;
                      default: {
                      }
                    }
                  }}
                ></RentalPeriod>

                {!contract && (
                  <Row>
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Label>{i18n.t("Original Price")}</Label>
                        <AvField
                          name="originalAmount"
                          placeholder={i18n.t("Original Amount")}
                          type="number"
                          errorMessage={i18n.t("invalid Original Amount")}
                          className="form-control  font-size-15"
                          id="validatiomOriginalAmount"
                          value={originalPrice}
                          validate={{
                            min: { value: 0 },
                            required: { value: true },
                          }}
                          onChange={(e: any) =>
                            setOriginalPrice(new Decimal(e.target.value ?? 0))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="3">
                      <FormGroup className="mb-4">
                        <Label>{i18n.t("Discount Amount")}</Label>
                        <AvField
                          name="discountAmount"
                          placeholder={i18n.t("Discount Amount")}
                          type="number"
                          errorMessage={i18n.t("invalid Discount Amount")}
                          className="form-control"
                          id="validatiomDiscountAmount"
                          value={discountAmount}
                          validate={{ max: { value: originalPrice } }}
                          onChange={(e: any) =>
                            setDiscountAmount(new Decimal(e.target.value ?? 0))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="3">
                      <FormGroup>
                        <Label>{i18n.t(" ")}</Label>
                        <BxButton
                          className="btn btn-secondary waves-effect mt-2 btn-block d-flex justify-content-between align-items-center font-size-13"
                          type="button"
                          color="secondary"
                          onClick={toggleVisibility}
                        >
                          {i18n.t("Apply Discounts")}
                          <i
                            className={`fas fa-chevron-${
                              isOpen ? "up" : "down"
                            } ml-2`}
                          ></i>
                        </BxButton>
                      </FormGroup>
                    </Col>
                    {/* DISCOUNT BUTTON SECTION START */}
                    {isOpen && (
                      <Row
                        style={{
                          backgroundColor: "#eaeeef",
                          border: "1px solid #dee2e6",
                          borderRadius: "0.25rem",
                          padding: "1rem",
                        }}
                        className="mb-4 mt-1"
                      >
                        <Col xs="12">
                          <div style={{ color: "red", marginBottom: "10px" }}>
                            {`(${i18n.t(
                              "ONLY Apply for the property."
                            )} ${i18n.t(
                              "One discount apply at the same time only"
                            )})`}
                          </div>
                        </Col>
                        <Col xs="6">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={discountType === "percentage"}
                                onChange={() =>
                                  handleCheckboxChange("percentage")
                                }
                                disabled={
                                  discountType !== null &&
                                  discountType !== "percentage"
                                }
                                style={{
                                  cursor:
                                    discountType !== null &&
                                    discountType !== "percentage"
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              />
                              <span
                                style={{
                                  color:
                                    discountType !== null &&
                                    discountType !== "percentage"
                                      ? "gray"
                                      : "black",
                                }}
                              >
                                {`${i18n.t("Apply Discount")} %`}
                              </span>
                            </Label>
                            <div className="input-group mt-2">
                              <Input
                                type="number"
                                value={discountPercentage}
                                onChange={(e) =>
                                  setDiscountPercentage(Number(e.target.value))
                                }
                                disabled={discountType !== "percentage"}
                                style={{
                                  backgroundColor:
                                    discountType !== "percentage"
                                      ? "#e9ecef"
                                      : "white",
                                }}
                              />
                              <Button
                                onClick={() =>
                                  applyDiscount(discountPercentage)
                                }
                                disabled={discountType !== "percentage"}
                              >
                                {i18n.t("Apply Discount")}
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={discountType === "freeDays"}
                                onChange={() =>
                                  handleCheckboxChange("freeDays")
                                }
                                disabled={
                                  discountType !== null &&
                                  discountType !== "freeDays"
                                }
                                style={{
                                  cursor:
                                    discountType !== null &&
                                    discountType !== "freeDays"
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              />
                              <span
                                style={{
                                  color:
                                    discountType !== null &&
                                    discountType !== "freeDays"
                                      ? "gray"
                                      : "black",
                                }}
                              >
                                {i18n.t("Apply X Day Free (as discount)")}
                                <a data-tip data-for="applyXDayFreeTips1">
                                  {` ${i18n.t("ⓘ")}`}
                                </a>
                              </span>
                              <ReactTooltip
                                id="applyXDayFreeTips1"
                                effect="solid"
                              >
                                <span>
                                  <p>
                                    {`${i18n.t(
                                      "Count the daily rate (Total Day / Rental Price) times X days."
                                    )}`}
                                  </p>
                                </span>
                              </ReactTooltip>
                            </Label>
                            <div className="input-group mt-2">
                              <Input
                                type="number"
                                value={freeDays}
                                onChange={(e) =>
                                  setFreeDays(Number(e.target.value))
                                }
                                disabled={discountType !== "freeDays"}
                                style={{
                                  backgroundColor:
                                    discountType !== "freeDays"
                                      ? "#e9ecef"
                                      : "white",
                                }}
                              />
                              <Button
                                onClick={() =>
                                  applyFreeDaysAsDiscount(freeDays)
                                }
                                disabled={discountType !== "freeDays"}
                              >
                                {i18n.t("Apply Free Days")}
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>

                        <Col xs="6">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={discountType === "freeMonths"}
                                onChange={() =>
                                  handleCheckboxChange("freeMonths")
                                }
                                disabled={
                                  discountType !== null &&
                                  discountType !== "freeMonths"
                                }
                                style={{
                                  cursor:
                                    discountType !== null &&
                                    discountType !== "freeMonths"
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              />
                              <span
                                style={{
                                  color:
                                    discountType !== null &&
                                    discountType !== "freeMonths"
                                      ? "gray"
                                      : "black",
                                }}
                              >
                                {i18n.t("Apply X Month Free (as discount)")}
                                <a data-tip data-for="applyXMonthFreeTips1">
                                  {` ${i18n.t("ⓘ")}`}
                                </a>
                              </span>

                              <ReactTooltip
                                id="applyXMonthFreeTips1"
                                effect="solid"
                              >
                                <span>
                                  <p>
                                    {`${i18n.t(
                                      "If the Rent Period is not MONTH, that will count the daily rate (Total Day / Rental Price) times 30 days."
                                    )}`}
                                  </p>
                                </span>
                              </ReactTooltip>
                            </Label>
                            <div className="input-group mt-2">
                              <Input
                                type="number"
                                value={freeMonths}
                                onChange={(e) =>
                                  setFreeMonths(Number(e.target.value))
                                }
                                disabled={discountType !== "freeMonths"}
                                style={{
                                  backgroundColor:
                                    discountType !== "freeMonths"
                                      ? "#e9ecef"
                                      : "white",
                                }}
                              />
                              <Button
                                onClick={() =>
                                  applyFreeMonthsAsDiscount(freeMonths)
                                }
                                disabled={discountType !== "freeMonths"}
                              >
                                {i18n.t("Apply Free Months")}
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={discountType === "addFreeMonths"}
                                onChange={() =>
                                  handleCheckboxChange("addFreeMonths")
                                }
                                disabled={
                                  discountType !== null &&
                                  discountType !== "addFreeMonths"
                                }
                                style={{
                                  cursor:
                                    discountType !== null &&
                                    discountType !== "addFreeMonths"
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              />
                              <span
                                style={{
                                  color:
                                    discountType !== null &&
                                    discountType !== "addFreeMonths"
                                      ? "gray"
                                      : "black",
                                }}
                              >
                                {i18n.t("Add X Month Free")}
                              </span>
                            </Label>
                            <div className="input-group mt-2">
                              <Input
                                type="number"
                                value={addFreeMonthsCount}
                                onChange={(e) =>
                                  setAddFreeMonthsCount(Number(e.target.value))
                                }
                                disabled={discountType !== "addFreeMonths"}
                                style={{
                                  backgroundColor:
                                    discountType !== "addFreeMonths"
                                      ? "#e9ecef"
                                      : "white",
                                }}
                              />
                              <Button
                                color={
                                  isAddingMonthChecked ? "primary" : "secondary"
                                }
                                onClick={() => {
                                  toggleFreeMonths(addFreeMonthsCount);
                                  setIsAddingMonthChecked(
                                    !isAddingMonthChecked
                                  );
                                }}
                                disabled={discountType !== "addFreeMonths"}
                              >
                                {isAddingMonthChecked
                                  ? i18n.t("Remove Free Months")
                                  : i18n.t("Add Free Months")}
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    <Col xs="12">
                      <FormGroup>
                        <Label>{i18n.t("Final Price")}</Label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <Input
                            disabled
                            className="bg-light text-gray bg-opacity-10 font-weight-bold font-size-15"
                            type="number"
                            maxLength={8}
                            name="finalPrice"
                            value={finalPrice?.toNumber()}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <FormGroup className="mb-4">
                  <Label>{i18n.t("Remarks")}</Label>

                  <Input
                    type="textarea"
                    maxLength={500}
                    name="remarks"
                    onChange={(e) => setRemarks(e.target.value)}
                    value={remarks}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          {props.rentMode === RentMode.ContinueRent ? (
            <BxButton
              type="submit"
              color="success"
              className="mt-3"
              form={"CollapseForm" + props.step}
            >
              {i18n.t(`${props.totalSteps > 1 ? "Next Step" : "Submit"}`)}
              {contract && ` ${contract?.id}`}
            </BxButton>
          ) : props.rentMode === RentMode.NewContract ? (
            <BxButton
              disabled={!!contract}
              type="submit"
              color="success"
              className="mt-3"
              form={"CollapseForm" + props.step}
            >
              {i18n.t(`${props.totalSteps > 1 ? "Next Step" : "Submit"}`)}
              {contract && ` ${contract?.master_contract_id}`}
            </BxButton>
          ) : props.rentMode === RentMode.NewMasterContract ? (
            <BxButton
              type="submit"
              color="success"
              className="mt-3"
              form={"CollapseForm" + props.step}
            >
              {i18n.t(`${props.totalSteps > 1 ? "Next Step" : "Submit"}`)}
              {contract && ` ${contract?.id}`}
            </BxButton>
          ) : (
            <BxButton
              disabled={!!contract}
              type="submit"
              color="success"
              className="mt-3"
              form={"CollapseForm" + props.step}
            >
              {i18n.t(`${props.totalSteps > 1 ? "Next Step" : "Submit"}`)}
              {contract && ` ${contract?.master_contract_id}`}
            </BxButton>
          )}
          {props.totalSteps === 1 && (
            <BxButton
              type="submit"
              color="secondary"
              className="mt-3 ml-2"
              form={"CollapseForm" + props.step}
              onClick={props.onCancel}
            >
              {i18n.t("Close")}
            </BxButton>
          )}
        </CardBody>
      </Card>
    </AvForm>
  );
};

export default ContractPanel;
